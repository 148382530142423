'use client';

import { useEffect } from 'react';

export const Eruda = ({ isDev }: { isDev?: boolean }) => {
  useEffect(() => {
    const hasDebugInQuery = window.location.search.includes('debug');
    const isNgrok = window.location.hostname.includes('ngrok');
    if (isDev || hasDebugInQuery || isNgrok) {
      void import('eruda').then((module) => {
        const eruda = module.default;
        eruda.init();
        (hasDebugInQuery || isNgrok) && eruda.show();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
