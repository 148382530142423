'use client';

/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

import Image from 'next/image';
import classNames from 'classnames';
import { UserIcon } from '@heroicons/react/24/outline';
import { UserButton, SignInButton, useAuth } from '@clerk/nextjs';

import { LanguageSelector } from './LanguageSelector';
import { DarkModeIcon } from './DarkModeIcon';

import { Caret } from '@/components/icons/icons';
import { LOCALE_COOKIE, LocaleKey, locales, SUGGESTED_LOCALE_COOKIE } from '@/constants/locales';
import { Anchor } from '@/components/Fields/Buttons';
import { Translation } from '@/components/Translation';
import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';

type SecondaryNavigationProps = {
  showDashboardButton?: boolean;
  isDashboard?: boolean;
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
  className?: string;
};

// TODO: translations

export const SecondaryNavigation = ({
  showDashboardButton,
  isDashboard,
  localeKey,
  hasDarkThemeCookie,
  className,
}: SecondaryNavigationProps) => {
  const { isSignedIn, isLoaded } = useAuth();
  const [userBtn, setUserBtn] = useState<React.ReactNode>(null);

  const [isIsLanguagesModalOpen, setIsLanguagesModalOpen] = useState(false);
  const locale = locales.find(({ id }) => id === localeKey);

  const [isReallySignedIn, setIsReallySignedIn] = useState(false); // <-- Need this to force re-render

  useEffect(() => {
    if (isSignedIn && isLoaded) {
      setIsReallySignedIn(true);
    }
  }, [isSignedIn, isLoaded]);

  useEffect(() => {
    if (isSignedIn) {
      setUserBtn(
        <UserButton afterSignOutUrl="/">
          <UserButton.MenuItems>
            <UserButton.Action
              labelIcon={
                <div className="flex w-[300px] flex-row items-center gap-6">
                  <CountryFlag className="h-4 -translate-x-1" code={locale?.country ?? 'en'} />
                  <span>
                    {String(locale?.actualTitle) ?? 'Select Language'}
                    <Caret className="ml-1.5 inline h-2.5 w-2.5 opacity-50" />
                  </span>
                </div>
              }
              label=" "
              onClick={() => setIsLanguagesModalOpen(true)}
            />
          </UserButton.MenuItems>
        </UserButton>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const onLanguageChange = (localeKey: LocaleKey) => {
    document.cookie = `${LOCALE_COOKIE}=${localeKey}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
    document.cookie = `${SUGGESTED_LOCALE_COOKIE}=${localeKey}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
    window.location.reload();
  };

  return (
    <div className={classNames('-mr-4 flex items-center justify-end pdf:!hidden print:!hidden xl:gap-2', className)}>
      <DarkModeIcon hasDarkThemeCookie={hasDarkThemeCookie} />

      {/* Loading content (non-dashboard) */}
      <div className={!isDashboard && (!isReallySignedIn || !isLoaded) ? undefined : 'hidden'} suppressHydrationWarning>
        <LanguageSelector
          localeKey={localeKey}
          onLanguageChange={onLanguageChange}
          redirectOnClick
          place="bottom-end"
        />
      </div>

      {/* Signed in content */}
      <div
        className={classNames(
          isReallySignedIn || isDashboard ? undefined : 'hidden',
          'flex items-center justify-end xl:gap-2',
        )}
        suppressHydrationWarning
      >
        {showDashboardButton && (
          <Anchor
            className="mr-4 bg-primary-100 hover:bg-primary-200/60 dark:bg-primary-500 dark:text-white dark:hover:bg-primary-500/90"
            href="/dashboard"
            suppressHydrationWarning
          >
            Dashboard
          </Anchor>
        )}
        {isIsLanguagesModalOpen && (
          <LanguageSelector
            localeKey={localeKey}
            onLanguageChange={onLanguageChange}
            place="bottom-end"
            anchor=".language-nav-anchor"
            redirectOnClick={!window.location.pathname.startsWith('/dashboard')}
            updateUserLocale
            open
          />
        )}
        <div className="language-nav-anchor mx-2 h-8 w-8 scale-[60%]" suppressHydrationWarning>
          {userBtn || (
            <div className="h-7 w-7">
              <Image
                src="/avatar.png"
                alt="avatar"
                width={32}
                height={32}
                className="h-full w-full rounded-full"
                suppressHydrationWarning
              />
            </div>
          )}
        </div>
      </div>

      {/* Signed out content */}
      <div className={!isReallySignedIn && !isDashboard ? undefined : 'hidden'} suppressHydrationWarning>
        <SignInButton mode="modal">
          <button
            className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-6 underline-offset-8 hover:bg-primary-500/5 dark:decoration-white/20 dark:hover:bg-white/5"
            suppressHydrationWarning
          >
            <UserIcon className="h-4" suppressHydrationWarning />
            <div className="whitespace-nowrap" suppressHydrationWarning>
              <Translation name="nav.item.sign-in">Sign in</Translation>
            </div>
          </button>
        </SignInButton>
      </div>
    </div>
  );
};
